var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"server-items-length":_vm.maxRecords,"options":_vm.options,"headers":_vm.headers,"items":_vm.retailersData,"search":_vm.seachInput},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id_client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_client)+" ")]}},{key:"item.fantasy_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fantasy_name)+" ")]}},{key:"item.franchise",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.franchise.fantasy_name; }) || '-')+" ")]}},{key:"item.erp_backoffice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.erp_backoffice.name; }) || '-')+" ")]}},{key:"item.erp_pdv",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.erp_pdv.name; }) || '-')+" ")]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cnpj")(item.cnpj))+" ")]}},{key:"item.total_stores",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.client_units.length; }) || '-')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.getStatusLabel(item.status).color}},[_vm._v(" fiber_manual_record ")]),_vm._v(" "+_vm._s(_vm.getStatusLabel(item.status).label)+" ")]}},{key:"item.mrr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm._f("localeString")(item.revenue.recurrent.total,2)))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goToEditRetailer(item)}}},[_c('v-list-item-title',[_vm._v("Ver detalhes")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.copyId(item)}}},[_c('v-list-item-title',[_vm._v("Copiar ID")])],1),(item.active)?_c('v-list-item',{on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-list-item-title',[_vm._v("Cadastrar Churn")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogHistory(item)}}},[_c('v-list-item-title',[_vm._v("Histórico de Churn")])],1),(!item.active)?_c('v-list-item',{on:{"click":function($event){return _vm.unsetRetailerChurn(item)}}},[_c('v-list-item-title',[_vm._v("Remover Churn")])],1):_vm._e(),_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.deleteRetailer(item)}}},[_vm._v("Deletar")])],1),_c('dialog-churn',{attrs:{"client_id":_vm.client_id,"retailer-name":_vm.retailer_name,"retailer-cnpj":_vm.retailer_cnpj,"value":_vm.dialog,"data-churn":_vm.item_churn,"readonly":_vm.readonly,"is-retailer":true},on:{"click":_vm.setChurn},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)]}}],null,true)}),_c('dialog-history-churn',{attrs:{"retailer-name":_vm.retailer_name,"list-churn":_vm.listChurn,"loading":_vm.loadingHistory,"retailer-cnpj":_vm.retailer_cnpj,"client-id":_vm.client_id},model:{value:(_vm.historyChurn),callback:function ($$v) {_vm.historyChurn=$$v},expression:"historyChurn"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }